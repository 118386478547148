import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-responsive-modal";
import Ink from "react-ink";

import { addProduct, removeProduct } from "../../../../services/cart/actions";
class MemberVariations extends Component {
    state = {
        update: true,
        open: false,
    };

    handlePopupOpen = () => {
        if (this.state.open) {
            this.handlePopupClose();
        }
        this.setState({ open: true });
    };

    forceStateUpdate = () => {
        this.props.forceUpdate();
        setTimeout(() => {
            if (this.state.update) {
                this.setState({ update: false });
            } else {
                this.setState({ update: true });
            }
        }, 100);
    };

    addProductVariant = (product, variant) => {
        
        if (variant) {
            product.selectedvariant = variant;
            // product.name += variant.name;
            product.price = variant.sale_price;
        }
        product.quantity = 1;
        this.props.addProduct(product);
    };


    handlePopupClose = () => {
        this.setState({ open: false });
        // this.props.forceUpdate();
    };

    __formatPrice = (price) => {
        return parseFloat(price).toFixed(0);
    }

    __itemAvailability = (item, variant) => {
        if (!item.has_variant) return true;

        if (item.variant_type === "P") {
            if (variant !== undefined) {
                return (variant.stock > 0 || variant.is_infinite || variant.is_negative);
            }

            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && variant.stock <= 0 && !variant.is_infinite && !variant.is_negative;
            });
            return is_item_out_of_stock.length ? false : true;
        } else {
            if (variant !== undefined) {
                if (!variant.is_infinite && !variant.is_negative) {
                    return item.variant_stock > 0;
                }
                return true;
            }

            let is_item_out_of_stock = item.variants.filter(variant => {
                return variant.is_default && (variant.is_infinite || variant.is_negative);
            });
            if (is_item_out_of_stock.length > 0) { return true; }

            return item.variant_stock > 0;
        }
    }

    __getDiscountPercentage = (variant) => {
        if (parseFloat(variant.sale_price) >= parseFloat(variant.regular_price)) {
            return false;
        }
        let discount = ((parseFloat(variant.regular_price) - parseFloat(variant.sale_price)) / parseFloat(variant.regular_price)) * 100;
        return discount !== 0 ? discount.toFixed(0) : false;
    }
    __formatPrice = (price) => {
        return parseFloat(price).toFixed(0);
    }
    __getRegularPrice = (item_variants) => {
        var variant = item_variants.find(function (o) { return o.is_default === 1 });
        if (variant && variant !== undefined && variant.regular_price !== undefined) {
            let sale_price = variant.sale_price;
            let regular_price = variant.regular_price;
            if (sale_price !== regular_price) {
                return parseFloat(regular_price).toFixed(0);
            }
        }
        return false;
    }
    __getSalePrice = (item_variants) => {
        var variant = item_variants.find(function (o) { return o.is_default === 1 });
        if (variant && variant !== undefined && variant.sale_price !== undefined) {
            let sale_price = variant.sale_price;
            return parseFloat(sale_price).toFixed(0);
        }
        return 0;
    }
    __getDiscountPercentage = (item) => {
        var discount = 0;
        if (item.has_variant) {
            let sale_price = this.__getSalePrice(item.variants);
            let regular_price = this.__getRegularPrice(item.variants);
            if (parseFloat(sale_price) >= parseFloat(regular_price)) {
                return false;
            }
            discount = ((parseFloat(regular_price) - parseFloat(sale_price)) / parseFloat(regular_price)) * 100;
        }
        return discount !== 0 ? discount.toFixed(0) : false;
    }
    render() {
        const { product, removeProduct, cartProducts, addByDefault, showDefaultVariant, hideAddController, itemType, hideAdd,outOfStock } = this.props;

        const isItemExistInPopupCart = (item_id, variant_id) => {
            const { cartProducts } = this.props;
            let cc = cartProducts.find(cartItem => {
                if (cartItem.selectedvariant !== undefined) {
                    return cartItem.id === item_id && cartItem.selectedvariant.id === variant_id;
                }
            });
            if (cc !== undefined) {
                return true;
            }
            return false;
        }

        const isItemExistInCart = (product) => {
            let cc = []
                cartProducts.map((cartproduct)=>{
                    if(cartproduct.id === product.id){
                       cc.push(cartproduct)
                    }
           } )
           let quantity = 0
            if(cc.length > 0){
                cc.map((item)=>{
                    quantity += item.quantity
                })
            }
           return quantity
        }

        const itemTotal = (product) => {
             let sumTotal = 0
             
                cartProducts.map((cartproduct)=>{
                    if(product.id === cartproduct.id){
                        sumTotal += cartproduct.quantity * cartproduct.price
                    }
                   
                })
             return sumTotal
        }

        const defaultVariant = product.variants[0];
        const totalAvailableVariants = product.variants.filter(variant => (variant.stock > 0 || variant.is_infinite || variant.is_negative));
        return (
            <React.Fragment>

                {(this.props.itemType == 'catList' || this.props.itemType == 'dealsItem') &&
                    <div className={this.props.itemType == 'dealsItem' ? 'my-0' : 'my-2'}>
                        {defaultVariant.name}
                    </div>
                }


                {
                    this.props.itemType == 'singleItem' ?
                        <div className="_2tfBo" style={{ alignItems: 'middle' }}>
                            <div className="_1WjtX" style={{ height: 'auto', overflow: 'visible' }}>
                                <div className="_3BRuO">

                                    {/* Price and discount offer Start */}
                                    <div className="item-color-price font-w600 text-left" style={{ marginTop: 0 }}>
                                        {product.has_variant ? (
                                            <React.Fragment>

                                                <div style={{ marginBottom: 7, marginTop: this.__getRegularPrice(product.variants) ? 0 : 7 }}>

                                                    <div className="d-flex align-items-end">
                                                        <div style={{
                                                            color: "rgb(54, 57, 62)",
                                                            marginRight: 2, font: "bold",
                                                            fontSize: '15pt'
                                                        }}
                                                            className="sale-price">
                                                            <div style={{ fontSize: 12, fontWeight: 500 }}>
                                                                {defaultVariant.name}
                                                            </div>

                                                            <div className={'d-flex align-items-center'}>
                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                    localStorage.getItem("currencyFormat")}
                                                                {this.__getSalePrice(product.variants)}

                                                                {this.__getRegularPrice(product.variants) && (
                                                                    <div className="regular-price" style={{ fontSize: 15 }}>
                                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                            localStorage.getItem("currencyFormat")}
                                                                        <s>{this.__getRegularPrice(product.variants)}</s>

                                                                    </div>
                                                                )}

                                                            </div>

                                                        </div>
                                                        {/* {this.__getDiscountPercentage(product) ? (
                                                           <div>
                                                                
                                                                <span style={{position:"relative",top:"2px",color:"rgb(151, 123, 13)"}}> ({this.__getDiscountPercentage(product)}%off)</span>
                                                                </div>
                                                        ) : (
                                                            <React.Fragment />
                                                        )} */}

                                                    </div>

                                                    <div className="inclusive-tax-text" style={{ fontSize: '.78rem', zIndex: 8, position: 'absolute', marginTop: -2 }}>{localStorage.getItem('inclusiveTaxText')}</div>


                                                </div>


                                            </React.Fragment>
                                        ) : (
                                            <span className="sale-price" style={{ color: "rgb(54, 57, 62)", marginRight: "10px", font: "bold" }}>
                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                    localStorage.getItem("currencyFormat")}{" "}
                                                {this.__formatPrice(product.price)}
                                                {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                    localStorage.getItem("currencyFormat")}
                                            </span>
                                        )}
                                    </div>
                                    {/* Price and discount offer End */}
                                </div>
                            </div>


                            <div className="" style={{ fontWeight: 800, fontSize: 14, color: 'rgb(27, 166, 114)', marginTop: this.props.itemType == 'FBItem' && 10 }}>
                                {product.variants.length > 1 ? (
                                    <React.Fragment>
                                        {isItemExistInCart(product) ? (
                                            <React.Fragment key={defaultVariant.id}>
                                                <div style={{
                                                    
                                                    background:this.props.itemType == "dealsItem1" && 'white',
                                                    border: '1px solid rgba(2, 6, 12, .15)',
                                                    boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                    borderRadius: 8, height: 32, width: 82, alignItems: 'center', display: 'flex', justifyContent: 'center'
                                                }}>
                                                    <div>
                                                        <div className="_15xoN" style={{ color: 'rgb(27, 166, 114)', fontWeight: 600 }}>
                                                            <span className="OnOhx _2racQ" style={{ color: 'rgb(27, 166, 114) !important', fontWeight: '600 !important' }}
                                                                onClick={this.handlePopupOpen}>
                                                                <i className="fa fa-minus" />
                                                            </span>

                                                            <span className="V_J6V _3a4vZ _2racQ"

                                                            >{isItemExistInCart(product)}</span>
                                                            <span className="_2racQ"
                                                                onClick={this.handlePopupOpen}
                                                                style={{ color: 'rgb(27, 166, 114)' }}
                                                            ><i className="fa fa-plus" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.props.itemType !== 'dealsItem' &&
                                                    <div className="vO7GWright" style={{ marginTop: this.props.itemType == 'FBItem' && 13 }}>View {product.variants.length} Options</div>
                                                }
                                            </React.Fragment>
                                        ) : (
                                            <div style={{
                                                background:this.props.itemType == 'dealsItem1' && 'white',
                                                border: '1px solid rgba(2, 6, 12, .15)',
                                                boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                borderRadius: 8, height: 32, width: 82, alignItems: 'center', display: 'flex', justifyContent: 'center'
                                            }}>
                                                <div role="button" onClick={this.handlePopupOpen}>
                                                    <span className="_3oVVP"><span className="_3oVVP _3rbRA">{product.variants.length} options</span></span>
                                                    <span className="_3OUJP">
                                                        <svg className="_1Eaan T15jh _2racQ" viewBox="0 0 8 6" fill="#60b246">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.195262 0.361888C0.455612 0.101539 0.877722 0.101539 1.13807 0.361888L4 3.22382L6.86193 0.361888C7.12228 0.101539 7.54439 0.101539 7.80474 0.361888C8.06509 0.622238 8.06509 1.04435 7.80474 1.3047L4.4714 4.63803C4.21106 4.89838 3.78894 4.89838 3.5286 4.63803L0.195262 1.3047C-0.0650874 1.04435 -0.0650874 0.622238 0.195262 0.361888Z" fill="#60b246"></path>
                                                        </svg>
                                                    </span>
                                                </div>
                                            </div>
                                        )}

                                    </React.Fragment>
                                ) : (
                                    <React.Fragment>
                                        {isItemExistInCart(product) ? (
                                            <React.Fragment key={defaultVariant.id}>
                                                <div style={{
                                                    background:this.props.itemType == "dealsItem1" && 'white',
                                                    border: '1px solid rgba(2, 6, 12, .15)',
                                                    boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                    borderRadius: 8, height: 32, width: 76, alignItems: 'center', display: 'flex', justifyContent: 'center'
                                                }}>
                                                    <div>
                                                        <div className="_15xoN" style={{ color: 'rgb(27, 166, 114)', fontWeight: 600 }}>
                                                            <span className=" OnOhx _2racQ" style={{ color: 'rgb(27, 166, 114)', fontWeight: 600 }}
                                                                onClick={() => {
                                                                    product.quantity = 1;
                                                                    product.selectedvariant = defaultVariant;
                                                                    removeProduct(product);
                                                                    this.forceStateUpdate();
                                                                }}><i className="fa fa-minus" /></span>
                                                            <span className="V_J6V _3a4vZ _2racQ"
                                                                data-variant-id={defaultVariant.id}
                                                                data-variant-name={defaultVariant.name}
                                                                data-variant-price={defaultVariant.price}
                                                            >{isItemExistInCart(product)}</span>

                                                            <span className="_2racQ"
                                                                onClick={() => {
                                                                    this.addProductVariant(product, defaultVariant);
                                                                    this.forceStateUpdate();
                                                                }}
                                                                style={{ color: 'rgb(27, 166, 114)' }}
                                                            ><i className="fa fa-plus" /></span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </React.Fragment>
                                        ) : (
                                            <div style={{
                                                background:this.props.itemType == 'dealsItem1' && 'white',
                                                border: '1px solid rgba(2, 6, 12, .15)',
                                                boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                borderRadius: 8, height: 32, width: 82, alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: this.props.itemType == 'dealsItem' && 4
                                            }}>
                                                <div role="button">
                                                    <span className="_3oVVP"><span className="_3oVVP"
                                                        onClick={() => {
                                                            this.addProductVariant(product, defaultVariant);
                                                            this.forceStateUpdate();
                                                        }}>ADD</span></span>
                                                </div>
                                            </div>
                                        )}
                                    </React.Fragment>
                                )}
                            </div>

                        </div>
                        :

                        <div className="_2tfBo" style={{
                            display: this.props.itemType == "dealsItem1" ? 'block' :'flex',
                            alignItems: (this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem' || this.props.itemType == 'dealsItem') ? 'middle' : 'flex-start',
                            height: this.props.itemType == 'dealsItem' && 40
                        }}>
                            <div className="_1WjtX" style={{ height: 'auto', overflow: 'visible',marginBottom:this.props.itemType == "dealsItem1" && 20 }}>
                                <div className="_3BRuO">

                                    {/* Price and discount offer Start */}
                                    <div className="item-color-price font-w600 text-left" style={{ marginTop: 0 }}>
                                        {product.has_variant ? (
                                            <React.Fragment>

                                                <div style={{ marginBottom: 7, marginTop: this.__getRegularPrice(product.variants) ? 0 : 7 }}>
                                                    {
                                                        this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem' &&
                                                        <>
                                                            <div className="d-flex align-items-end">
                                                                <div style={{
                                                                    color: "rgb(54, 57, 62)",
                                                                    marginRight: 2, font: "bold",
                                                                    fontSize: '15pt'
                                                                }}
                                                                    className="sale-price">
                                                                    <div style={{ fontSize: 12, fontWeight: 500 }}>
                                                                        {defaultVariant.name}
                                                                    </div>
                                                                    {(this.props.itemType == 'catList') &&
                                                                        <>
                                                                            {this.__getRegularPrice(product.variants) && (
                                                                                <s className="regular-price"
                                                                                    style={{
                                                                                        fontSize: (this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem') ? 10 : 12,
                                                                                        marginTop: (this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem') ? 27 : 0,
                                                                                        fontWeight: (this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem') ? 'normal' : 200
                                                                                    }}>
                                                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                        localStorage.getItem("currencyFormat")}
                                                                                    {this.__getRegularPrice(product.variants)}
                                                                                </s>
                                                                            )}
                                                                        </>
                                                                    }
                                                                    <div className={(this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem') && 'd-flex align-items-center'} style={{
                                                                        marginTop: (this.props.itemType == 'catList') && this.__getRegularPrice(product.variants) && -8
                                                                    }}>
                                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                            localStorage.getItem("currencyFormat")}
                                                                        {this.__getSalePrice(product.variants)}
                                                                        {(this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem') &&
                                                                            <>
                                                                                {this.__getRegularPrice(product.variants) && (
                                                                                    <div className="regular-price" style={{ fontSize: 15 }}>
                                                                                        {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                                            localStorage.getItem("currencyFormat")}
                                                                                        <s>{this.__getRegularPrice(product.variants)}</s>

                                                                                    </div>
                                                                                )}
                                                                            </>
                                                                        }
                                                                    </div>

                                                                </div>
                                                                {/* {this.__getDiscountPercentage(product) ? (
                                                           <div>
                                                                
                                                                <span style={{position:"relative",top:"2px",color:"rgb(151, 123, 13)"}}> ({this.__getDiscountPercentage(product)}%off)</span>
                                                                </div>
                                                        ) : (
                                                            <React.Fragment />
                                                        )} */}

                                                            </div>
                                                            {this.props.itemType == 'singleItem' || this.props.itemType == 'FBItem' &&
                                                                <div className="inclusive-tax-text" style={{ fontSize: '.78rem', zIndex: 8, position: 'absolute', marginTop: -2 }}>{localStorage.getItem('inclusiveTaxText')}</div>
                                                            }
                                                        </>
                                                    }



                                                    {
                                                        this.props.itemType == 'catList' &&
                                                        <div className="sale-price" style={{ color: "rgb(54, 57, 62)", marginRight: 0, font: "bold", fontSize: 13 }} >

                                                            {this.__getRegularPrice(product.variants) && (
                                                                <s className="regular-price"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        marginTop: 0,
                                                                        fontWeight: 200, lineHeight: '12px', fontFamily: 'Basis_Grotesque_Regular'
                                                                    }}>
                                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                        localStorage.getItem("currencyFormat")}
                                                                    {this.__getRegularPrice(product.variants)}
                                                                </s>
                                                            )}
                                                            <div style={{
                                                                marginTop: -4, fontFamily: 'Basis_Grotesque_Pro_Bold', fontWeight: 700, fontSize: 16,
                                                                lineHeight: '18px', letterSpacing: '-0.3px', color: 'rgba(2, 6, 12, 0.75)'
                                                            }}>
                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                    localStorage.getItem("currencyFormat")}
                                                                {this.__getSalePrice(product.variants)}
                                                            </div>

                                                        </div>
                                                    }


                                                    {
                                                        this.props.itemType == 'dealsItem' &&
                                                        <div className="sale-price" style={{ color: "rgb(54, 57, 62)", marginRight: 0, font: "bold", fontSize: 13 }} >

                                                            {this.__getRegularPrice(product.variants) && (
                                                                <s className="regular-price"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        marginTop: 0,
                                                                        fontWeight: 200, lineHeight: '12px', fontFamily: 'Basis_Grotesque_Regular'
                                                                    }}>
                                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                        localStorage.getItem("currencyFormat")}
                                                                    {this.__getRegularPrice(product.variants)}
                                                                </s>
                                                            )}
                                                            <div style={{
                                                                marginTop: -2, fontFamily: 'Basis_Grotesque_Pro_Bold', fontWeight: 700, fontSize: 16,
                                                                lineHeight: '18px', letterSpacing: '-0.3px', color: 'rgba(2, 6, 12, 0.75)'
                                                            }}>
                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                    localStorage.getItem("currencyFormat")}
                                                                {this.__getSalePrice(product.variants)}
                                                            </div>

                                                        </div>
                                                    }
                                                    {
                                                         this.props.itemType == "dealsItem1" &&
                                                         <div className="sale-price" style={{ color: "white", marginRight: 0, font: "bold", fontSize: 13 }} >
 
                                                             {this.__getRegularPrice(product.variants) && (
                                                                 <s className="regular-price"
                                                                     style={{
                                                                         fontSize: 12,
                                                                         marginTop: 0,
                                                                         fontWeight: 200, lineHeight: '12px', fontFamily: 'Basis_Grotesque_Regular'
                                                                     }}>
                                                                     {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                         localStorage.getItem("currencyFormat")}
                                                                     {this.__getRegularPrice(product.variants)}
                                                                 </s>
                                                             )}
                                                             <div style={{
                                                                 marginTop: this.__getRegularPrice(product.variants) ? -2 : 15, fontFamily: 'Basis_Grotesque_Pro_Bold', fontWeight: 700, fontSize: 16,
                                                                 lineHeight: '18px', letterSpacing: '-0.3px', color: 'white'
                                                             }}>
                                                                 {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                     localStorage.getItem("currencyFormat")}
                                                                 {this.__getSalePrice(product.variants)}
                                                             </div>
 
                                                         </div>
                                                    }

                                                    {
                                                        this.props.itemType == 'searchItem' &&
                                                        <div className="sale-price" style={{ color: "rgb(54, 57, 62)", marginRight: 0, font: "bold", fontSize: 13 }} >

                                                            {this.__getRegularPrice(product.variants) && (
                                                                <s className="regular-price"
                                                                    style={{
                                                                        fontSize: 12,
                                                                        marginTop: 0,
                                                                        fontWeight: 200, lineHeight: '12px', fontFamily: 'Basis_Grotesque_Regular'
                                                                    }}>
                                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                        localStorage.getItem("currencyFormat")}
                                                                    {this.__getRegularPrice(product.variants)}
                                                                </s>
                                                            )}
                                                            <div style={{
                                                                marginTop: -4, fontFamily: 'Basis_Grotesque_Pro_Bold', fontWeight: 700, fontSize: 16,
                                                                lineHeight: '18px', letterSpacing: '-0.3px', color: 'rgba(2, 6, 12, 0.75)'
                                                            }}>
                                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                                    localStorage.getItem("currencyFormat")}
                                                                {this.__getSalePrice(product.variants)}
                                                            </div>

                                                        </div>
                                                    }

                                                </div>


                                            </React.Fragment>
                                        ) : (
                                            <span className="sale-price" style={{ color: "rgb(54, 57, 62)", marginRight: "10px", font: "bold" }}>
                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                    localStorage.getItem("currencyFormat")}{" "}
                                                {this.__formatPrice(product.price)}
                                                {localStorage.getItem("currencySymbolAlign") === "right" &&
                                                    localStorage.getItem("currencyFormat")}
                                            </span>
                                        )}
                                    </div>
                                    {/* Price and discount offer End */}
                                </div>
                            </div>
                            {
                                this.props.hideAdd == true ?
                                    <>
                                    </>
                                    :

                                    <div className="" style={{ fontWeight: 800, fontSize: 14, color: 'rgb(27, 166, 114)', marginTop: this.props.itemType == 'FBItem' && 10 }}>
                                        {product.variants.length > 1 ? (
                                            <React.Fragment>
                                                {isItemExistInCart(product) ? (
                                                    <React.Fragment key={defaultVariant.id}>
                                                        <div style={{
                                                            background:this.props.itemType == "dealsItem1" && 'white',
                                                            border: '1px solid rgba(2, 6, 12, .15)',
                                                            boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                            borderRadius: 8, height: 32, width: 82, alignItems: 'center', display: 'flex', justifyContent: 'center'
                                                        }}>
                                                            <div>
                                                                <div className="_15xoN" style={{ color: 'rgb(27, 166, 114)', fontWeight: 600 }}>
                                                                    <span className="OnOhx _2racQ" style={{ color: 'rgb(27, 166, 114) !important', fontWeight: '600 !important' }}
                                                                        onClick={this.handlePopupOpen}>
                                                                        <i className="fa fa-minus" />
                                                                    </span>

                                                                    <span className="V_J6V _3a4vZ _2racQ"

                                                                    >{isItemExistInCart(product)}</span>
                                                                    <span className="_2racQ"
                                                                        onClick={this.handlePopupOpen}
                                                                        style={{ color: 'rgb(27, 166, 114)' }}
                                                                    ><i className="fa fa-plus" /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {
                                                            this.props.itemType !== 'dealsItem' &&
                                                            <>
                                                            {
                                                                this.props.itemType == 'dealsItem1' ?
                                                                <div className="vO7GWright" style={{ marginTop:0,
                                                                color:'white',float:'left',fontWeight:'normal' }}>View {product.variants.length} Options</div>
                                                                :
                                                                <div className="vO7GWright" style={{ marginTop: this.props.itemType == 'FBItem' && 13,
                                                            color:this.props.itemType == "dealsItem1" && 'white',float:this.props.itemType == "dealsItem1" ? 'left':'right' }}>
                                                                View {product.variants.length} Options</div>
                                                            }
                                                            
                                                            </>
                                                        }
                                                        
                                                    </React.Fragment>
                                                ) : (
                                                    <>
                                                    {
                                                            !outOfStock ?
                                                    <div style={{
                                                        background:this.props.itemType == 'dealsItem1' && 'white',
                                                        border: '1px solid rgba(2, 6, 12, .15)',
                                                        boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                        borderRadius: 8, height: 32, width: 82, alignItems: 'center', display: 'flex', justifyContent: 'center'
                                                    }}>
                                                        
                                                        <div role="button" onClick={this.handlePopupOpen}>
                                                            <span className="_3oVVP"><span className="_3oVVP _3rbRA">{product.variants.length} options</span></span>
                                                            <span className="_3OUJP">
                                                                <svg className="_1Eaan T15jh _2racQ" viewBox="0 0 8 6" fill="#60b246">
                                                                    <path fillRule="evenodd" clipRule="evenodd" d="M0.195262 0.361888C0.455612 0.101539 0.877722 0.101539 1.13807 0.361888L4 3.22382L6.86193 0.361888C7.12228 0.101539 7.54439 0.101539 7.80474 0.361888C8.06509 0.622238 8.06509 1.04435 7.80474 1.3047L4.4714 4.63803C4.21106 4.89838 3.78894 4.89838 3.5286 4.63803L0.195262 1.3047C-0.0650874 1.04435 -0.0650874 0.622238 0.195262 0.361888Z" fill="#60b246"></path>
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div style={{
                                                        background:this.props.itemType == 'dealsItem1' && 'white',
                                                        border: '1px solid rgba(2, 6, 12, .15)',
                                                        boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                        borderRadius: 8, height: 32, width: 82, alignItems: 'center', display: 'flex', justifyContent: 'center'
                                                    }}>
                                                        
                                                        <div role="button" disabled={true}>
                                                            <span className="_3oVVP"><span className="_3oVVP _3rbRA" style={{fontSize:12,color:'rgba(2, 6, 12, .3)'}}>Out of Stock</span></span>
                                                        </div>
                                                    </div>
                                                }
                                                    </>
                                                )}

                                            </React.Fragment>
                                        ) : (
                                            <React.Fragment>
                                                {isItemExistInCart(product) ? (
                                                    <React.Fragment key={defaultVariant.id}>
                                                        <div style={{
                                                            background:this.props.itemType == "dealsItem1" && 'white',
                                                            border: '1px solid rgba(2, 6, 12, .15)',
                                                            boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                            borderRadius: 8, height: 32, width: 76, alignItems: 'center', display: 'flex', justifyContent: 'center'
                                                        }}>
                                                            <div>
                                                                <div className="_15xoN" style={{ color: 'rgb(27, 166, 114)', fontWeight: 600 }}>
                                                                    <span className=" OnOhx _2racQ" style={{ color: 'rgb(27, 166, 114)', fontWeight: 600 }}
                                                                        onClick={() => {
                                                                            product.quantity = 1;
                                                                            product.selectedvariant = defaultVariant;
                                                                            removeProduct(product);
                                                                            this.forceStateUpdate();
                                                                        }}><i className="fa fa-minus" /></span>
                                                                    <span className="V_J6V _3a4vZ _2racQ"
                                                                        data-variant-id={defaultVariant.id}
                                                                        data-variant-name={defaultVariant.name}
                                                                        data-variant-price={defaultVariant.price}
                                                                    >{isItemExistInCart(product)}</span>

                                                                    <span className="_2racQ"
                                                                        onClick={() => {
                                                                            this.addProductVariant(product, defaultVariant);
                                                                            this.forceStateUpdate();
                                                                        }}
                                                                        style={{ color: 'rgb(27, 166, 114)' }}
                                                                    ><i className="fa fa-plus" /></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </React.Fragment>
                                                ) : (
                                                    <div style={{
                                                        background:this.props.itemType == 'dealsItem1' && 'white',
                                                        border: '1px solid rgba(2, 6, 12, .15)',
                                                        boxShadow: ' 0 3px 8px rgba(40,44,63,.08)',
                                                        borderRadius: 8, height: 32, width: 82, alignItems: 'center', display: 'flex', justifyContent: 'center', marginBottom: this.props.itemType == 'dealsItem' && 4
                                                    }}>
                                                        <div role="button">
                                                            <span className="_3oVVP"><span className="_3oVVP"
                                                                onClick={() => {
                                                                    this.addProductVariant(product, defaultVariant);
                                                                    this.forceStateUpdate();
                                                                }}>ADD</span></span>
                                                        </div>
                                                    </div>
                                                )}
                                            </React.Fragment>
                                        )}
                                    </div>
                            }
                        </div>
                }
                {
                    this.props.itemType == 'dealsItem' &&
                        <div style={{ height: 10 }}>
                            {product.variants.length > 1 && (
                                <React.Fragment>
                                    {isItemExistInCart(product) ?
                                        <>
                                            {
                                                this.props.itemType == 'dealsItem' &&
                                                <div className="vO7GWright" style={{ marginTop: 1, color: '#7e808c', fontWeight: 800 }}>View {product.variants.length} Options</div>
                                            }
                                        </>
                                        :
                                        <>
                                        </>
                                    }
                                </React.Fragment>
                            )
                        }
                        </div>
                }

                <Modal open={this.state.open} onClose={this.handlePopupClose} closeIconSize={32}>
                    <div
                        style={{
                            textAlign: "left",
                        }}
                    >
                        <div style={{fontFamily: 'Basis_Grotesque_Pro_Bold',fontWeight: 700,fontSize: 18,lineHeight: '24px',letterSpacing: '-0.3px',color: 'rgba(2, 6, 12, 0.75)'}} 
                        className="mb-2">
                            {product.name}
                        </div>
                        {/* <hr className="mt-10" style={{ borderColor: "#ccc" }} /> */}
                        {product.variants.map((variant, index) => (
                            <React.Fragment key={variant.id}>
                              
                                <div className="single-variant mt-20" style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                    position: "relative",
                                    alignItems: 'center',
                                    boxShadow:'0 2px 8px rgba(40,44,63,.08)',
                                    filter: !this.__itemAvailability(product, variant) && 'blur(1.5px)',
                                    borderRadius:16,
                                    padding:8
                                }}>
                                    <div style={{width:135}}>

                                        {
                                            product.image !== null &&
                                            <img src={product.image} style={{ width: 64, height: 64 }} />
                                        }
                                        <label className="text addon-label" style={{ marginBottom: 0 }} htmlFor={variant.name}>
                                            <span className="variant-name">{variant.name}</span> <br />
                                        </label>
                                    </div>
                                    {
                                        variant.sale_price === variant.regular_price ?
                                            <div style={{
                                                marginTop: -4, fontFamily: 'Basis_Grotesque_Pro_Bold', fontWeight: 700, fontSize: 16,
                                                lineHeight: '18px', letterSpacing: '-0.3px', color: 'rgba(2, 6, 12, 0.75)'
                                            }}>
                                                {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                    localStorage.getItem("currencyFormat")}
                                                {parseFloat(variant.regular_price).toFixed(0)}
                                            </div>
                                            :

                                            <div className="sale-price" style={{ color: "rgb(54, 57, 62)", marginRight: 0, font: "bold", fontSize: 13 }} >
                                                <s className="regular-price" style={{ fontSize: 12, marginTop: 0, fontWeight: 200, lineHeight: '12px', fontFamily: 'Basis_Grotesque_Regular' }}>
                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                        localStorage.getItem("currencyFormat")}
                                                    {parseFloat(variant.regular_price).toFixed(0)}
                                                </s>

                                                <div style={{
                                                    marginTop: -4, fontFamily: 'Basis_Grotesque_Pro_Bold', fontWeight: 700, fontSize: 16,
                                                    lineHeight: '18px', letterSpacing: '-0.3px', color: 'rgba(2, 6, 12, 0.75)'
                                                }}>
                                                    {localStorage.getItem("currencySymbolAlign") === "left" &&
                                                        localStorage.getItem("currencyFormat")}
                                                    {parseFloat(variant.sale_price).toFixed(0)}
                                                </div>

                                            </div>
                                    }
                                   
                                    {!this.__itemAvailability(product, variant) ? (
                                        <div className="" style={{position:'relative'}}>{localStorage.getItem('outOfStockText')}</div>
                                    ) : (
                                        <div className="item-actions pull-right">
                                            <div
                                                className="btn-group btn-group-sm"
                                                role="group"
                                                aria-label="btnGroupIcons1"
                                                style={{ top: "0px", width: 82,height:32,border: '1px solid rgba(2, 6, 12, 0.15)',
                                                boxShadow:'rgba(40, 44, 63, 0.08) 0px 3px 8px',
                                                borderRadius: 8,padding:2 }}>
                                                
                                                {isItemExistInPopupCart(product.id, variant.id) ? (
                                                    <React.Fragment key={variant.id}>
                                                        <button
                                                            type="button"
                                                            className=""
                                                            style={{
                                                                color: 'rgb(27, 166, 114)',
                                                                backgroundColor:'white',
                                                                width:27,
                                                                border:'none',
                                                                borderRadius:8,fontWeight:600
                                                            }}
                                                            onClick={() => {
                                                                product.quantity = 1;
                                                                product.selectedvariant = variant;
                                                                removeProduct(product);
                                                                this.forceStateUpdate();
                                                            }}

                                                        >
                                                            <span className="btn-dec">-</span>
                                                        </button>

                                                        <input
                                                            type="text"
                                                            disabled
                                                            value={cartProducts.find(
                                                                (cp) =>
                                                                    cp.id ===
                                                                    product.id && cp.selectedvariant.id === variant.id
                                                            ).quantity}
                                                            data-variant-id={variant.id}
                                                            data-variant-name={variant.name}
                                                            data-variant-price={variant.price}
                                                            className="textbox-qty"
                                                            style={{width:27,fontWeight:600,lineHeight: '25px',
                                                                color: 'rgb(27, 166, 114)'}}
                                                        />

                                                        <button
                                                            type="button"
                                                            style={{
                                                                color: localStorage.getItem("cartColor-bg"),
                                                                backgroundColor:'white',
                                                                width:27,
                                                                border:'none',
                                                                borderRadius:8,fontWeight:600
                                                            }}
                                                            onClick={() => {
                                                                this.addProductVariant(product, variant);
                                                                this.forceStateUpdate();
                                                            }}
                                                        >
                                                            <span className="btn-inc">+</span>
                                                          
                                                        </button>
                                                    </React.Fragment>
                                                ) : (
                                                    <React.Fragment>
                                                        
                                                        <button
                                                            type="button"
                                                            className=""
                                                            style={{
                                                                color: localStorage.getItem("cartColor-bg"),
                                                                width: 82,border:'none',
                                                                background:'white',padding:2,color: 'rgb(27, 166, 114)',
                                                                fontWeight:600,lineHeight: '25px',fontSize:16
                                                            }}
                                                            onClick={() => {
                                                                this.addProductVariant(product, variant);
                                                                this.forceStateUpdate();
                                                            }}
                                                        >
                                                            Add
                                                        </button>
                                                    </React.Fragment>
                                                )}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </React.Fragment>
                        ))}
                        <button
                            className="btn btn-lg btn-customization-done mt-10 d-flex justify-content-between align-items-center"
                            onClick={() => {
                                this.handlePopupClose();
                            }}
                            style={{
                                backgroundColor: localStorage.getItem("cartColorBg"),
                                color: localStorage.getItem("cartColorText"),
                                borderRadius:12,
                                fontFamily: 'Basis_Grotesque_Pro_Bold',fontWeight: 700,
                                fontSize: 16,lineHeight: '20px',letterSpacing: '-0.3px',
                                color: 'rgb(255, 255, 255)',textTransform:'none'}}>
                            <div>
                            <span className="mr-2">Item total :</span>
                            {localStorage.getItem("currencySymbolAlign") === "left" &&
                                localStorage.getItem("currencyFormat")} {itemTotal(product)}
                            </div>
                            <div>
                            {localStorage.getItem("productVariationDoneBtnText")}
                            </div>
                           
                        </button>
                    </div>

                </Modal>
            </React.Fragment>
        );
    }

}
const mapStateToProps = (state) => ({
    cartProducts: state.cart.products
});

export default connect(
    mapStateToProps,
    {
        addProduct,
        removeProduct
    }
)(MemberVariations);