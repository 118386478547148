import React, { Component } from "react";

import DelayLink from "../../helpers/delayLink";
import Ink from "react-ink";
import Meta from "../../helpers/meta";
import { NavLink } from "react-router-dom";
import ProgressiveImage from "react-progressive-image";
import { Redirect } from "react-router";
import { connect } from "react-redux";
import { getSettings } from "../../../services/settings/actions";
import { getSingleLanguageData } from "../../../services/languages/actions";
import Nav from "../Nav";
import Lottie from "lottie-react";
import ContentLoader from "react-content-loader";
import Modal from "react-responsive-modal";
import { GET_ADDRESS_FROM_COORDINATES } from "../../../configs";
import Axios from "axios";
class FirstScreen extends Component {
	state = {
		splashVersion: 1,
		animationData: null,
		open:true
	};
	static contextTypes = {
		router: () => null,
	};
	async componentDidMount() {
		if (localStorage.getItem("storeColor")) {
			setTimeout(() => {
				this.removeSplashScreen();
			}, 1000);
		}
		let splashVersion = Math.random() * Math.floor(9999999);
		localStorage.setItem("splashVersion", splashVersion);
		this.setState({ splashVersion: splashVersion });
		const response = await fetch('https://lottie.host/a15bc3e0-2342-4259-b869-6633cbb9ba99/KY2ihKl3Ou.json');
       
        const data = await response.json();
        this.setState({ animationData: data });
	}

	removeSplashScreen = () => {
		if (document.getElementById("firstScreenSplash")) {
			document.getElementById("firstScreenSplash").remove();
		}
		if (document.getElementById("firstScreenMain")) {
			document.getElementById("firstScreenMain").classList.remove("hidden");
		}
	};

	handleOnChange = (event) => {
		// console.log(event.target.value);
		this.props.getSingleLanguageData(event.target.value);
		localStorage.setItem("userPreferedLanguage", event.target.value);
	};

	componentWillReceiveProps(nextProps) {
		const { settings } = this.props;
		if (settings !== nextProps.settings) {
			//settings received, then hide the splash screen after 2s
			setTimeout(() => {
				this.removeSplashScreen();
			}, 1000);
		}

		if (this.props.languages !== nextProps.languages) {
			if (localStorage.getItem("userPreferedLanguage")) {
				this.props.getSingleLanguageData(localStorage.getItem("userPreferedLanguage"));
				// console.log("Called 1");
			} else {
				if (nextProps.languages.length) {
					const id = nextProps.languages.filter((lang) => lang.is_default === 1)[0].id;
					this.props.getSingleLanguageData(id);
				}
			}
		}
	}
	handleGeoLocationClick = (results) => {
		// console.log(results);
		const saveGeoLocation = new Promise((resolve) => {
			localStorage.setItem("geoLocation", JSON.stringify(results[0]));
			resolve("GeoLocation Saved");
		});
		saveGeoLocation.then(() => {
			this.setState({ gps_loading: false });
			this.context.router.history.push("/my-location");
		});
	};
	getMyLocation = () => {
		const location = navigator && navigator.geolocation;
		console.log("LOCATION", location);
		this.setState({ gps_loading: true });
		if (location) {
			location.getCurrentPosition(
				(position) => {
					this.reverseLookup(position.coords.latitude, position.coords.longitude);
				},
				(error) => {
					this.setState({ gps_loading: false });
					console.log(error);
					alert(localStorage.getItem("gpsAccessNotGrantedMsg"));
				},
				{ timeout: 5000 }
			);
		}
	};
	reverseLookup = (lat, lng) => {
		Axios.post(GET_ADDRESS_FROM_COORDINATES, {
			lat: lat,
			lng: lng,
		})
			.then((response) => {
				console.log(response);
				const myLocation = [
					{
						formatted_address: response.data,
						geometry: {
							location: {
								lat: lat,
								lng: lng,
							},
						},
					},
				];
				this.handleGeoLocationClick(myLocation);
			})
			.catch(function(error) {
				console.warn(error.response.data);
			});
	};
	render() {
		const { user,history } = this.props;

		if (localStorage.getItem("userSetAddress") !== null) {
			return <Redirect to="/stores" />;
		}
		return (
			<React.Fragment>
				<Meta
					seotitle={localStorage.getItem("seoMetaTitle")}
					seodescription={localStorage.getItem("seoMetaDescription")}
					ogtype="website"
					ogtitle={localStorage.getItem("seoOgTitle")}
					ogdescription={localStorage.getItem("seoOgDescription")}
					ogurl={window.location.href}
					twittertitle={localStorage.getItem("seoTwitterTitle")}
					twitterdescription={localStorage.getItem("seoTwitterDescription")}
				/>
				<div className="firstScreenDiv">
				<div className="" style={{backgroundImage:`url('/assets/img/homeV1headerBG.avif')`,paddingTop:10,filter:"blur(0px) brightness(0.4)"}}>

<Nav 
	logo={true}
	active_nearme={true}
	disable_back_button={true}
	history={history}
	loggedin={user.success}
/>
<div style={{position:'absolute',top:18,left:36,zIndex:2}}>
                        <div
                            className="btn nav-location nav-home-location truncate-text mb-5"
                            style={{ position: "relative", maxWidth: "100%",background:'none' }}
                        >
                           
                                <React.Fragment>
                                    <span className="text-normal deliver-to" style={{fontFamily: 'Basis_Grotesque_Pro_Bold',
                                    fontWeight: 700,fontSize: 16,lineHeight: '19px',letterSpacing: '-0.3px',color: 'rgba(2, 6, 12, 0.92)',
                                    textTransform:'none'}}>
                                        Delivery to <img style={{width:15}} src="/assets/img/downArrow.png"/>
                                    </span>
                                    <div className="deliver-to-with-address" style={{left:-7}}>
                                            <span style={{fontFamily: 'Basis_Grotesque_Regular',fontWeight: 200,
                                            fontSize: 13,lineHeight: '16px',letterSpacing: '-0.3px',color: 'rgba(2, 6, 12, 0.6)'}}>
                                            Select Location
                                            </span>
                                    </div>
                                </React.Fragment>
                            
                            <Ink duration="500" />
                        </div>
                    </div>

					<div style={{backgroundImage:`url('/assets/img/homeV1BG2.png')`,position:'relative',height:120,backgroundPosition:'center',backgroundSize:'contain',marginTop:10}}>
                    <img style={{position:'absolute',right:20,width:70,height:35,top:10}} src="/assets/img/cloudHomeV1BG.webp" />
                    <img style={{position:'absolute',left:20,top:40,width:50,height:25}} src="/assets/img/cloudHomeV1BG.webp" />
                    <div className="d-flex justify-content-center">
                    <Lottie style={{height:250,width:500,position:'absolute',top:0,display:'flex',justifyContent:'center'}} animationData={this.state.animationData} loop={true} />
                    </div>

                </div>

                <div style={{background:'white'}}>
                <div style={{background:'white',paddingLeft:15,paddingBottom:0,paddingTop:120}} 
                className="shop-by-category-content-section" >
					
					<div  style={{background:'white',display:'flex',justifyContent:'center',paddingBottom:20,paddingTop:50}}>
                    <img style={{width:390,height:30}} src="/assets/img/exploreNow.avif" />
                </div>        
                </div>
                            
                
                </div>

				
</div>
<Modal open={this.state.open}  closeIconSize={0}>
					<div style={{height:500}}>
					<div style={{height:150,overflow:'hidden',background:'#F8CB46',paddingTop:30,paddingLeft:30,paddingRight:30}}>
						<img style={{width:'100%'}} src="/assets/img/blinkItPopupPhonesImg.webp" />
					</div>
					<div style={{display:'flex',justifyContent:'center',position:'relative',top:-38}}>
						<img style={{width:72,height:72}} src="/assets/img/blinkItLogo.webp" />
					</div>
					<div className="block" style={{background:'white',marginTop:-30}}>
							<div className="block-content">
								<h1 className="welcome-heading ">{localStorage.getItem("firstScreenHeading")}</h1>
								{/* <h2 className="welcome-subheading text-center mt-10 mb-10">
									{localStorage.getItem("firstScreenSubHeading")}
								</h2> */}

								<div style={{marginTop:30}}>
								<button
						className="btn btn-current-location btn-gps btn-md d-flex justify-content-center align-items-center"
						style={{ 
                            textAlign:'center',
							width:'100%'
                        }}
						onClick={this.getMyLocation}
					>
						<div>
						<img src="/assets/img/gps.svg" />
						</div>
						
						{/* <i className="si si-pointer" /> */}
						<div className="ml-2" style={{fontWeight:'bold'}}>
						{localStorage.getItem("useCurrentLocationText")}
						</div> 
					</button>
					<div className="d-flex justify-content-center align-items-center my-10" style={{fontSize:18,fontWeight:'bold'}}>
						--or--
					</div>

					<div onClick={()=>{this.context.router.history.push("/search-location");}} className="px-15 d-flex justify-content-between align-items-center" 
					style={{borderRadius:12,overflow:'hidden',background: '#f0f0f5',margin: '0px 20px',padding: '18px 10px'}}>
                                          
                                                <div className="d-flex" style={{fontWeight: 'bold',
                                            fontSize: 16,lineHeight: '19px',letterSpacing: '-0.3px',color: 'rgba(2, 6, 12, 0.45)'}}>
                                              {localStorage.getItem("searchAreaPlaceholder")}
                                            </div>
                                            <div>
                                                <img style={{width:20}} src="./assets/img/searchSvg.svg" alt="search-location" />
                                            </div>
                                        </div>
								{/* <DelayLink
									to="/search-location"
									delay={200}
									className="btn btn-lg btn-setup-location"
									style={{
										backgroundColor:`${localStorage.getItem("storeColor")}`,
                                            width: "70%",
                                            borderRadius: 12,
											color:'white',
											fontSize: 18,
										position: "relative",
									}}
								>
									{localStorage.getItem("firstScreenSetupLocation")}
									<Ink duration="500" hasTouch="true" />
								</DelayLink> */}
								{user.success ? (
									<p className="login-block font-w500 mb-0">
										{localStorage.getItem("firstScreenWelcomeMessage")} {user.data.name}
									</p>
								) : (
									<p className="login-block mb-0" style={{fontSize:16,fontWeight:'bold'}}>
										{localStorage.getItem("firstScreenLoginText")}{" "}
										<NavLink to="/login" style={{ color: localStorage.getItem("storeColor"),fontWeight:'bold' }}>
											{localStorage.getItem("firstScreenLoginBtn")}
										</NavLink>
									</p>
								)}

								{this.props.languages && this.props.languages.length > 1 && (
									<div className="mt-4 d-flex align-items-center justify-content-center mb-100">
										<div className="mr-2">{localStorage.getItem("changeLanguageText")}</div>
										<select
											onChange={this.handleOnChange}
											defaultValue={
												localStorage.getItem("userPreferedLanguage")
													? localStorage.getItem("userPreferedLanguage")
													: this.props.languages.filter((lang) => lang.is_default === 1)[0].id
											}
											className="form-control language-select"
										>
											{this.props.languages.map((language) => (
												<option value={language.id} key={language.id}>
													{language.language_name}
												</option>
											))}
										</select>
									</div>
								)}
								</div>
							</div>
						</div>
					</div>
				</Modal>
					{/* <div className="col-12 p-0" id="firstScreenSplash">
						<div className="block m-0">
							<div className="block-content p-0">
								<img
									src={`/assets/img/splash/splash.jpg?v=${this.state.splashVersion}`}
									className="img-fluid"
									alt={localStorage.getItem("storeName")}
									style={{
										width: "100%",
									}}
								/>
							</div>
						</div>
					</div>
					<div
						className="col-12 p-0 hidden"
						id="firstScreenMain"
						style={{ height: `${window.innerHeight}px` }}
					>
						<div className="block m-0 ">
							<div className="block-content p-0">
								{localStorage.getItem("firstScreenHeroImage") && (
									<ProgressiveImage
										delay={100}
										src={localStorage.getItem("firstScreenHeroImage")}
										placeholder={localStorage.getItem("firstScreenHeroImageSm")}
									>
										{(src, loading) => (
											<img
												src={src}
												alt={localStorage.getItem("storeName")}
												className="img-fluid"
												style={{
													filter: loading ? "blur(1.2px) brightness(0.9)" : "none",
													width: `${window.innerWidth}px`,
												}}
											/>
										)}
									</ProgressiveImage>
								)}
							</div>
						</div>
						<div className="block m-0">
							<div className="block-content pt-10">
								<h1 className="welcome-heading mt-10">{localStorage.getItem("firstScreenHeading")}</h1>
								<h2 className="welcome-subheading text-center mt-10 mb-10">
									{localStorage.getItem("firstScreenSubHeading")}
								</h2>
								<DelayLink
									to="/search-location"
									delay={200}
									className="btn btn-lg btn-setup-location"
									style={{
										backgroundColor: localStorage.getItem("storeColor"),
										position: "relative",
									}}
								>
									{localStorage.getItem("firstScreenSetupLocation")}
									<Ink duration="500" hasTouch="true" />
								</DelayLink>
								{user.success ? (
									<p className="login-block font-w500 mb-0">
										{localStorage.getItem("firstScreenWelcomeMessage")} {user.data.name}
									</p>
								) : (
									<p className="login-block mb-0">
										{localStorage.getItem("firstScreenLoginText")}{" "}
										<NavLink to="/login" style={{ color: localStorage.getItem("storeColor") }}>
											{localStorage.getItem("firstScreenLoginBtn")}
										</NavLink>
									</p>
								)}

								{this.props.languages && this.props.languages.length > 1 && (
									<div className="mt-4 d-flex align-items-center justify-content-center mb-100">
										<div className="mr-2">{localStorage.getItem("changeLanguageText")}</div>
										<select
											onChange={this.handleOnChange}
											defaultValue={
												localStorage.getItem("userPreferedLanguage")
													? localStorage.getItem("userPreferedLanguage")
													: this.props.languages.filter((lang) => lang.is_default === 1)[0].id
											}
											className="form-control language-select"
										>
											{this.props.languages.map((language) => (
												<option value={language.id} key={language.id}>
													{language.language_name}
												</option>
											))}
										</select>
									</div>
								)}
							</div>
						</div>
					</div> */}
				</div>
			</React.Fragment>
		);
	}
}

const mapStateToProps = (state) => ({
	settings: state.settings.settings,
	user: state.user.user,
	languages: state.languages.languages,
	language: state.languages.language,
	restaurants_item_categories: state.restaurant.restaurants_item_categories,
});

export default connect(
	mapStateToProps,
	{ getSettings, getSingleLanguageData }
)(FirstScreen);
